/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "BlingsAPI",
            "endpoint": "https://8ndg966ws3.execute-api.eu-west-1.amazonaws.com/testlog",
            "region": "eu-west-1"
        },
        {
            "name": "addPubSubPermission",
            "endpoint": "https://gkn1vjhxl7.execute-api.eu-west-1.amazonaws.com/testlog",
            "region": "eu-west-1"
        },
        {
            "name": "extension",
            "endpoint": "https://k19ko8ud04.execute-api.eu-west-1.amazonaws.com/testlog",
            "region": "eu-west-1"
        },
        {
            "name": "platformRest",
            "endpoint": "https://vi1z06mrf9.execute-api.eu-west-1.amazonaws.com/testlog",
            "region": "eu-west-1"
        },
        {
            "name": "projectsRestaApi",
            "endpoint": "https://5xxwypmgi1.execute-api.eu-west-1.amazonaws.com/testlog",
            "region": "eu-west-1"
        },
        {
            "name": "uploadCsvFile",
            "endpoint": "https://lfpdnmhc00.execute-api.eu-west-1.amazonaws.com/testlog",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://2d3aworupncz3dsr7c5fbgopby.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-7l73ahahpraxrdoyshkub4zczi",
    "aws_cognito_identity_pool_id": "eu-west-1:56fd6c08-5d29-4057-8074-99e3ba775fa2",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ChjOcsDyG",
    "aws_user_pools_web_client_id": "78juadr0r36bj4odtcb0vupg3g",
    "oauth": {
        "domain": "platform480c79fd7-80c79fd7-testlog.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://platform.blings.io/auth/sign-in/,https://studio.blings.io/auth/sign-in/",
        "redirectSignOut": "https://platform.blings.io/auth/sign-out/,https://studio.blings.io/auth/sign-out/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "platform4-hostingbucket-dev-testlog",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://platform4-hostingbucket-dev-testlog.s3-website-eu-west-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Config-testlog",
            "region": "eu-west-1"
        },
        {
            "tableName": "SourceDataRecords-testlog",
            "region": "eu-west-1"
        },
        {
            "tableName": "Versions-testlog",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "platform-storage-bucket1b099-testlog",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
