import { Form, Input, InputNumber, Popover, Typography } from "antd";
import { useEffect, useState } from "react";
import "./DynamicChangeComponent.scss";
import staticText from "../../utils/staticText";
import {
  EditVideoExposedToPlatformType,
  EditVideoInteractiveType,
  EditVideoTextType,
  EditVideoTypes,
  UpdateVideoParams,
  AllowedInteractiveTypes,
  UpdateInteractiveParams,
} from "../../types/EditVideoTypes";
import { BlingsInput } from "../../components/BlingsInput/BlingsInput";
import SaveEditVideoComponent from "./SaveEditVideoComponent";
import { useMst } from "../../stores/Root";
import { observer } from "mobx-react-lite";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  getUrlType,
  isTextLayer,
  isExposedToPlatformMod,
  isInteractiveMod,
} from "./utils";
import { ExperimentOptimizationTechnique } from "../../API";
import { AIPlusIcon } from "../../assets/Icons";
import { HorizontalDivider } from "../../components/HorizontalDivider";
const { textLayer: text } = staticText;
type Props = {
  layerOrMod: EditVideoTypes;
  update: (updateVideoParams: UpdateVideoParams, isSave?: boolean) => void;
  collapse: (layer: EditVideoTypes) => void;
};

const DynamicChangeComponent = observer(
  ({ layerOrMod, collapse, update }: Props) => {
    const {
      saveModsStatus,
      createNewVariant,
      createNewExperiment,
      getExperiment,
      deleteVariantByIndex,
      updateVariantByIndex,
      isExperimentUnsaved,
      uploadAssetToProject,
      selectedExperimentOptimizationTechnique,
    } = useMst((store) => {
      const { projectsStore, experimentStore, liveControlStore } = store;
      return {
        saveModsStatus: projectsStore.selectedProject?.saveModsStatus,
        createNewExperiment: experimentStore.createNewDraftExperiment,
        createNewVariant: experimentStore.createNewDraftVariant,
        getExperiment: experimentStore.getDraftExperiment,
        deleteVariantByIndex: experimentStore.deleteDraftVariantByIndex,
        updateVariantByIndex: experimentStore.updateDraftVariantByIndex,
        isExperimentUnsaved: experimentStore.isExperimentDraftUnsaved,
        selectedExperimentOptimizationTechnique:
          experimentStore.selectedExperimentOptimizationTechnique,
        uploadAssetToProject: liveControlStore.uploadAssetToProject,
      };
    });
    const [ctaName, setCtaName] = useState<string>(
      isInteractiveMod(layerOrMod) ? layerOrMod.ctaName : ""
    );
    const getExplanation = (interactiveType: AllowedInteractiveTypes) => {
      switch (interactiveType) {
        case "interactiveConcat":
          return "Button to initiate a scene transition, creating new video trajectories and timelines.";
        case "interactiveGoto":
          return "Button that triggers a frame jump in the video.";
        case "interactiveForm":
          return "Button that triggers the submission of input fields in the video.";
        case "interactiveJSExpression":
          return "Button that triggers a custom Javascript expression.";
      }
    };
    const [experimentId, setExperimentId] = useState<string | undefined>(
      layerOrMod.experimentId
    );
    // keep track of all values from the variant options array
    const initialValue = isTextLayer(layerOrMod)
      ? layerOrMod.currentText != null
        ? layerOrMod.currentText
        : layerOrMod.text
      : layerOrMod?.value;

    const getFontSize = isTextLayer(layerOrMod) ? layerOrMod.fontSize : 1;
    const [value, setValue] = useState<string | undefined>(initialValue);
    const [fontSize, setFontSize] = useState<number | undefined>(getFontSize);
    const [experimentValues, setExperimentValues] = useState<string[]>([]);
    const [currentTimer, setCurrentTimer] = useState<any>(null);
    const hasExperimentsEnabled =
      selectedExperimentOptimizationTechnique &&
      selectedExperimentOptimizationTechnique !==
        ExperimentOptimizationTechnique.DISABLED;
    const variantData =
      layerOrMod.experimentId !== undefined
        ? getExperiment(layerOrMod.experimentId)
        : undefined;
    const experimentVariants =
      layerOrMod.experimentId !== undefined ? variantData?.variants : undefined;
    const hasVariant =
      layerOrMod.experimentId &&
      experimentVariants &&
      experimentVariants.length >= 2;
    useEffect(() => {
      if (
        experimentVariants &&
        experimentVariants?.length !== experimentValues.length
      ) {
        const newValues = experimentVariants.map(
          (variant: any) => (variant.value as string) || ""
        );
        setExperimentValues(newValues);
      }
    }, [experimentVariants?.length]);
    // TODO setSelectedOptionByIndex and setSelectedOptionByValue to preview a specific option
    const experimentsEnabled =
      hasExperimentsEnabled &&
      (isExposedToPlatformMod(layerOrMod) ||
        isTextLayer(layerOrMod) ||
        (isInteractiveMod(layerOrMod) &&
          layerOrMod.type === "interactiveOpen" &&
          (layerOrMod.value != null || layerOrMod.experimentId != null)) ||
        layerOrMod.experimentId != null);
    const infoPopover = (
      <Popover
        className="tooltip-1"
        placement="top"
        overlayInnerStyle={{ width: "300px" }}
        content={
          <div style={{ fontSize: "13px" }}>
            Enable AI-based Text Variation: Enhance your message by
            incorporating AI Optimization. For instance, substitute the greeting
            "Hello" with variants like "Hi" or "Hey" to leverage automatic
            optimization for improved success.
          </div>
        }
      >
        <div>
          <QuestionCircleOutlined
            style={{
              color: "var(--blings_icon_gray)",
              cursor: "pointer",
              marginRight: "0.1rem",
            }}
          />
        </div>
      </Popover>
    );

    const isSaveDisabled = () => {
      if (hasVariant && isExperimentUnsaved(experimentId)) {
        return false;
      } else if (isInteractiveMod(layerOrMod)) {
        const isCtaChange = ctaName !== layerOrMod.initialCtaName;
        return (
          ((layerOrMod?.type !== "interactiveOpen" ||
            layerOrMod?.value == null) &&
            !ctaName) ||
          (!isCtaChange &&
            !(layerOrMod.value != null && value !== layerOrMod.initialValue))
        );
      } else if (isTextLayer(layerOrMod)) {
        const isDisabled =
          (layerOrMod.initialText != null
            ? value === layerOrMod.initialText
            : value === layerOrMod.text) &&
          fontSize === layerOrMod.initialFontSize;
        return value == null || isDisabled;
      } else {
        return value == null || value === layerOrMod.initialValue;
      }
    };
    const onExperimentVariantChange = (value: string, index: number) => {
      const newValues = [...experimentValues];
      newValues[index] = value;
      setExperimentValues(newValues);
      updateVariantByIndex(experimentId, index, value);
    };
    const onVariantDelete = (index: number) => {
      deleteVariantByIndex(experimentId, index);
      const newValues = [...experimentValues];
      newValues.splice(index, 1);
      setExperimentValues(newValues);
    };
    const onVariantCreate = () => {
      const value = "Add new variant value";
      createNewVariant(experimentId, value);
      const newValues = [...experimentValues];
      newValues.push(value);
      setExperimentValues(newValues);
    };

    const handleExperimentChange = (experiment?: any, isSave?: boolean) => {
      setExperimentId(experiment);
      if (currentTimer) clearTimeout(currentTimer);
      if (!isSave) {
        const timer = setTimeout(() => {
          update({
            layerOrMod,
            input: { experimentId: experiment },
          } as UpdateVideoParams);
        }, 1000);
        setCurrentTimer(timer);
      } else {
        update(
          {
            layerOrMod,
            input: { experimentId: experimentId },
          } as UpdateVideoParams,
          isSave
        );
      }
    };

    const deleteExperiments = () => {
      const value = experimentValues[0];
      setExperimentId("");
      handleExperimentChange("");
      setValue(value);
      if (currentTimer) clearTimeout(currentTimer);
      const timer = setTimeout(() => {
        handleValueChange(value);
      }, 1000);
      setCurrentTimer(timer);
    };

    const handleValueChange = (newValue: any, isSave?: boolean) => {
      if (currentTimer) clearTimeout(currentTimer);
      setValue(newValue);
      if (!isSave) {
        const timer = setTimeout(() => {
          update({
            layerOrMod,
            input: { newValue: newValue, newFontValue: fontSize },
          } as UpdateVideoParams);
        }, 1000);
        setCurrentTimer(timer);
      } else {
        if (newValue != null) {
          update(
            {
              layerOrMod,
              input: { newValue: newValue, newFontValue: fontSize },
            } as UpdateVideoParams,
            isSave
          );
        }
      }
    };
    const handleFontChange = (newValue: any) => {
      if (currentTimer) clearTimeout(currentTimer);
      setFontSize(newValue);
      const timer = setTimeout(() => {
        update({
          layerOrMod,
          input: { newValue: value, newFontValue: newValue },
        } as UpdateVideoParams);
      }, 1000);
      setCurrentTimer(timer);
    };

    const handleCtaChange = (newVal: string) => {
      layerOrMod as EditVideoInteractiveType;
      if (currentTimer) clearTimeout(currentTimer);
      setCtaName(newVal);
      const timer = setTimeout(() => {
        update({
          layerOrMod,
          input: { newCtaName: newVal, newValue: value },
        } as UpdateInteractiveParams);
      }, 1000);
      setCurrentTimer(timer);
    };

    const renderTextInput = (index?: number) => {
      const typedLayerOrMod = layerOrMod as EditVideoTextType;
      return (
        <>
          {index != null && (
            <HorizontalDivider
              text={`Variant ${index + 1}`}
              {...(index >= 1 && {
                onIconClick: () => {
                  onVariantDelete(index);
                  if (experimentVariants.length <= 1) {
                    deleteExperiments();
                  }
                },
              })}
            />
          )}
          <Form.Item>
            <div className="outside-blingsInput">
              <label>Update:</label>
              <BlingsInput
                restoreValue={typedLayerOrMod.text}
                // label={text.CHANGE_TEXT}
                value={index != null ? experimentValues[index] : value}
                onChange={(value) => {
                  index != null
                    ? onExperimentVariantChange(value, index)
                    : handleValueChange(value);
                }}
              />
            </div>
          </Form.Item>
        </>
      );
    };
    const renderInteractiveInput = (index?: number) => {
      const typedLayerOrMod = layerOrMod as EditVideoInteractiveType;
      return (
        <>
          {!(index && index > 0) && (
            <Form.Item>
              <Input
                addonBefore="CTA Name"
                value={ctaName}
                onChange={(e) => handleCtaChange(e.target.value)}
              />
            </Form.Item>
          )}
          {index != null && (
            <HorizontalDivider
              text={`Variant ${index + 1}`}
              {...(index >= 1 && {
                onIconClick: () => {
                  onVariantDelete(index);
                  if (experimentVariants.length <= 1) {
                    deleteExperiments();
                  }
                },
              })}
            />
          )}
          {typedLayerOrMod.type === "interactiveOpen" &&
            (typedLayerOrMod?.value != null ||
              typedLayerOrMod.experimentId != null) && (
              <Form.Item>
                <div className="outside-blingsInput">
                  <label>Update:</label>
                  <BlingsInput
                    restoreValue={typedLayerOrMod.initialValue}
                    label={"Link"}
                    value={index != null ? experimentValues[index] : value}
                    onChange={(value) => {
                      index != null
                        ? onExperimentVariantChange(value, index)
                        : handleValueChange(value);
                    }}
                  />
                </div>
              </Form.Item>
            )}
          {typedLayerOrMod.type !== "interactiveOpen" ||
          typedLayerOrMod?.value == null ? (
            <div className="type-explanation">
              {getExplanation(typedLayerOrMod.type)}
            </div>
          ) : (
            ""
          )}
        </>
      );
    };
    const renderExposedToPlatformInput = (index?: number) => {
      const typedLayerOrMod = layerOrMod as EditVideoExposedToPlatformType;
      return (
        <>
          {index != null && (
            <HorizontalDivider
              text={`Variant ${index + 1}`}
              {...(index >= 1 && {
                onIconClick: () => {
                  onVariantDelete(index);
                  if (experimentVariants.length <= 1) {
                    deleteExperiments();
                  }
                },
              })}
            />
          )}
          <Form.Item>
            <div className="outside-blingsInput">
              <label>Update:</label>
              <BlingsInput
                restoreValue={typedLayerOrMod.initialValue}
                label={"Value"}
                mediaType={getUrlType(value || "")}
                value={index != null ? experimentValues[index] : value}
                onChange={(value) => {
                  index != null
                    ? onExperimentVariantChange(value, index)
                    : handleValueChange(value);
                }}
                {...(typedLayerOrMod.type === "asset"
                  ? { uploadAssetsToProject: uploadAssetToProject }
                  : {})}
                {...(typedLayerOrMod.type === "themeColor"
                  ? { colorPicker: true }
                  : {})}
                {...(typedLayerOrMod.type === "countdown"
                  ? { date: true }
                  : {})}
              />
            </div>
          </Form.Item>
        </>
      );
    };
    return (
      <Form className={"DynamicEditForm"}>
        {hasVariant && experimentVariants ? (
          <div className="variant-viewport">
            {experimentValues.map((variant, index) => {
              return (
                <Form.Item>
                  <div key={index}>
                    {isTextLayer(layerOrMod)
                      ? renderTextInput(index)
                      : isInteractiveMod(layerOrMod)
                      ? renderInteractiveInput(index)
                      : renderExposedToPlatformInput(index)}
                    <div></div>
                  </div>
                </Form.Item>
              );
            })}
            <div
              className="variant-add-container"
              onClick={() => {
                onVariantCreate();
              }}
            >
              <div className="add-ai-icon">
                <AIPlusIcon />
              </div>
              <Typography.Text
                style={{
                  marginRight: "0.5rem",
                  textAlign: "center",
                  margin: "0",
                }}
              >
                Add Variant
              </Typography.Text>
              {infoPopover}
            </div>
          </div>
        ) : (
          <>
            <Form.Item>
              {isTextLayer(layerOrMod)
                ? renderTextInput()
                : isInteractiveMod(layerOrMod)
                ? renderInteractiveInput()
                : renderExposedToPlatformInput()}
            </Form.Item>
            {experimentsEnabled && (
              <div
                className="variant-add-container"
                onClick={() => {
                  const vId = createNewExperiment(
                    value,
                    "Add new variant value"
                  );
                  handleExperimentChange(vId);
                }}
              >
                <div className="add-ai-icon">
                  <AIPlusIcon />
                </div>
                <Typography.Text
                  style={{
                    marginRight: "0.5rem",
                    textAlign: "center",
                    margin: "0",
                  }}
                >
                  Add Variant
                </Typography.Text>
                {infoPopover}
              </div>
            )}
          </>
        )}
        <div
          className="text-save"
          style={{
            justifyContent: !isTextLayer(layerOrMod) ? "end" : "space-between",
          }}
        >
          {isTextLayer(layerOrMod) ? (
            <Form.Item>
              <div className="outside-blingsInput">
                <label style={{ marginBottom: 0 }}>Font Size:</label>
                <InputNumber
                  value={fontSize}
                  min={1}
                  max={800}
                  onChange={handleFontChange}
                />
              </div>
            </Form.Item>
          ) : null}
          <Form.Item>
            <div className={"button-secondary"}>
              <SaveEditVideoComponent
                disabled={!!isSaveDisabled()}
                saveModsStatus={saveModsStatus}
                onclick={() => {
                  hasVariant
                    ? handleExperimentChange(experimentId, true)
                    : handleValueChange(value, true);
                }}
              />
            </div>
          </Form.Item>
        </div>
      </Form>
    );
  }
);

export default DynamicChangeComponent;
